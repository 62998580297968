//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiArrowRight, mdiClose, mdiMenu, mdiMessageOutline } from "@mdi/js";

export default {
  data() {
    return {
      mdiArrowRight,
      mdiClose,
      mdiMenu,
      mdiMessageOutline,
      menu: [
        {
          text: "About",
          link: "/about/",
        },
        {
          text: "Work",
          link: "/digital-marketing-services/",
        },
        {
          text: "Approach",
          link: "/approach/",
        },
        {
          text: "Careers",
          link: "/careers/",
        },
      ],
      showContactForm: false,
      showDrawer: false,
    };
  },
  computed: {
    headerHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 64 : 96;
    },
  },
};
