import { render, staticRenderFns } from "./error.vue?vue&type=template&id=d4f0b478&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalHeader: require('/codebuild/output/src1189782248/src/aisle-rocket-2022/components/storyblok/Global/Header.vue').default,ImageBackground: require('/codebuild/output/src1189782248/src/aisle-rocket-2022/components/Image/Background.vue').default,GlobalFooter: require('/codebuild/output/src1189782248/src/aisle-rocket-2022/components/storyblok/Global/Footer.vue').default})
